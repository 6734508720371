import React from 'react';
import {
  MDBFooter,
  MDBContainer
} from 'mdb-react-ui-kit';
import { FaFacebookF, FaTwitter, FaInstagram, FaLinkedinIn } from 'react-icons/fa'; // Importando ícones de redes sociais do react-icons
// import './footer.css'
import { Button, Col, Container, Row, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';


export default function Footer({endOfPage}) {
  return (
    <MDBFooter className='text-center' color='white' bgColor='dark' ref={endOfPage}>
      <MDBContainer className='p-4'>

      <section className='bg-dark p-4'>
  <Container>
    <Form>
      <Row className='d-flex justify-content-center align-items-center'>
        <Col lg='auto' xs='12'>
          <p className='d-flex flex-column'>
            <strong>Inscreva-se na Newsletter</strong>
            <span>Receba informações sobre conteúdos novos</span>
          </p>
        </Col>
        <Col lg='auto' xs='12'>
          <Form.Group controlId='formBasicEmail' className='mb-0 p-2'>
            <Form.Control type='email' placeholder='Escreva o seu email' />
          </Form.Group>
        </Col>
        <Col lg='auto' xs='12'>
          <Button                 
                className="btn btn-dark me-2" 
                style={{color: '#FF6347', transition: '.3s', border: '1px solid #FF6347', fontWeight: '700'}}   
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#FF6347'; 
                  e.currentTarget.style.color = '#332d2d'; 
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = 'transparent'; 
                  e.currentTarget.style.color = '#FF6347'; 
                }} type='submit'>
            Inscreva-se
          </Button>
        </Col>
      </Row>
    </Form>
  </Container>
</section>


    <section className='bg-dark p-3'>
      <Row className='d-flex align-items-center justify-content-center'>
        <Col lg='3' md='6' className='mb-4 mb-md-0'>
          <h5 className='text-uppercase text-white'>Redes Sociais</h5>
          <ul className='list-inline mb-0'>
            <li className='list-inline-item'>
              <a href='#!' className='text-white'>
                <FaFacebookF />
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='#!' className='text-white'>
                <FaTwitter />
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='#!' className='text-white'>
                <FaInstagram />
              </a>
            </li>
            <li className='list-inline-item'>
              <a href='#!' className='text-white'>
                <FaLinkedinIn />
              </a>
            </li>
          </ul>
        </Col>
      </Row>
    </section>

      </MDBContainer>

    <Container fluid className="text-center p-3 d-flex align-items-center justify-content-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}>
        <Link className='text-white p-2' to='/termos'>
          Termos e Condições
        </Link>
        <Link className='text-white p-2' to='/privacidade'>
          Privacidades e Cookies
        </Link>
    </Container>

    </MDBFooter>
  );
}