import React, { useRef, useState } from 'react';
import Navbar from '../components/Navbar/Navbar';
import { Container } from 'react-bootstrap';
import Last7Blogs from '../components/BlogCatalog/Last7Blogs';
import BlogGrid6 from '../components/BlogCatalog/BlogGrid6';
import AdGrid3 from '../components/Ads/AdGrid3';
import AdSmallBanner from '../components/Ads/AdSmallBanner';
import AdBigBanner from '../components/Ads/AdBigBanner';
import BlogHeadlineGrid3 from '../components/BlogCatalog/BlogHeadlineGrid3';
import Footer from '../components/Footer/Footer';
import Paginate from '../components/Pagination/Paginate';
import SlideshowBlogCard from '../components/BlogCard/SlideshowBlogCard';

export default function Destaques() {
  const endOfPageRef = useRef(null);

  const scrollToBottom = () => {
    endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalItems = 840;
  const itemsPerPage = 8;

  const blogInfo = [{titulo: 'AAAAAAAAAAAAAAAA a AAAAAAAA', categoria: 'abacate', img:'a.jpg'}, {titulo: 'BBBB', categoria: 'Banana', img:'MainAfter.png'}, {titulo: 'CCCC', categoria:'ABROBRINHA', img:'weekend.png'}];


  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  

  return (
    <div
      style={{
        maxHeight: "100vh",
        maxWidth: "100%",
        overflowX: "hidden",
      }}
    >
      <Navbar newsletterEvent={scrollToBottom} />
      <Container className="d-flex flex-column align-items-center">
        <AdSmallBanner />

      <SlideshowBlogCard blogsInfo={blogInfo} />

        DO 8 ATÉ ACABAR (INDICE 7 - ...) -> paginação
        <BlogGrid6 />

        <Paginate
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />

        <AdBigBanner />

      ALEATORIZA 1 CATEGORIA E 3 BLOGS DESSA CATEGORIA PARA APARECER
      <BlogHeadlineGrid3 />
      

      </Container>

      <AdGrid3 />

      <Footer endOfPage={endOfPageRef} />

    </div>
  );
}
