import React from 'react';
import { Container } from 'react-bootstrap';

export default function ImageBlog({img, references}) {
  return (
    <Container
      className="my-lg-4 my-2 text-muted"
    >
      <img
        className="img-fluid mx-auto d-block"
        style={{ maxWidth: "100%", height: "auto" }}
        src={`${process.env.PUBLIC_URL}/assets/${img}`}
        alt="Blog Image"
      />
      <p 
        className='mx-auto'
        style={{maxWidth: '100%', fontSize: ".8em"}}
            >Referências: {references}</p>
    </Container>
  );
}
