import React, { useState } from 'react';
import { Card, Badge, Row, Col } from 'react-bootstrap';
import FormatText from '../../utils/FormatText';
import { Link } from 'react-router-dom';

export default function HeadlineBlogCard({headline, category, title, subtitle, img}) {
  const FormattedCategory = category ? FormatText(category) : '';

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredCategory, setIsHoveredCategory] = useState(false);

  const linkBlog = `/blog/${FormatText(title)}`


  /* HOVER */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterCategory = () => {
    setIsHoveredCategory(true);
  };
  const handleMouseLeaveCategory = () => {
    setIsHoveredCategory(false);
  };

  /* ONCLICK */

  return (
    <Card className='mb-4 bg-light' style={{ width: '100%' }}>
      <Row>
        <Col md={12} className="text-center">

          <Card.Title
            className='mb-3 mt-2'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
              <Link
                style={{fontWeight: "bold", fontSize: '1.3em', color: isHovered ? '#777' : 'black', cursor: 'pointer', textDecoration:"none"}}
                to={linkBlog}>
                  {headline}
              </Link>          
          </Card.Title>

          <Link to={linkBlog}>
            <Card.Img
              className='px-3 pt-3'
              variant="top"
              src={`${process.env.PUBLIC_URL}/assets/${img}`} 
              style={{ width: '100%', height: 'auto', margin: '0px auto', padding: '0px', cursor: 'pointer'}}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            />
          </Link>
        
        <Card.Body className='text-center p-2'>
          <Card.Text
              className='mb-1'
              onMouseEnter={handleMouseEnterCategory}
              onMouseLeave={handleMouseLeaveCategory}
              >
              <Link
              style={{fontWeight: 'bold', fontSize: '.9em', margin: '0 auto', cursor: 'pointer', color: isHoveredCategory ? '#FA8072' : '#FF6347',}}
              to={`/categoria/${FormattedCategory}`}>
                {category}
              </Link>            
          </Card.Text>

            <Card.Title
              className='mb-3'
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              >
                <Link
                  style={{fontWeight: 'bold', fontSize: '1.5em', color: isHovered ? '#777' : 'black', cursor: 'pointer', textDecoration:"none"}}
                  to={linkBlog}>
                    {title}
                </Link>          
            </Card.Title>

            <Card.Subtitle 
              className="mb-2 text-muted"
              style={{}}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              >
                <Link
                  style={{fontSize: '1em', color: 'black', cursor: 'pointer', textDecoration:"none"}}
                  to={linkBlog}>
                    {subtitle}
                </Link>      
            </Card.Subtitle>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}
