import React from 'react'
import { Container } from 'react-bootstrap'

export default function AdGrid3() {
  return (
    <div>
        <Container className="d-flex justify-content-center my-3 banner p-1 mb-5">
          <div className='d-flex align-items-center justify-content-center'>
              <img
                className='p-2'
                style={{ width: '20%', height: '100%' }}
                src={`${process.env.PUBLIC_URL}/assets/weekend.png`}
                alt="Banner Publicidade"
              />

              <img
                className='p-2'
                style={{ width: '20%', height: '100%' }}
                src={`${process.env.PUBLIC_URL}/assets/weekend.png`}
                alt="Banner Publicidade"
              />

              <img
                className='p-2'
                style={{ width: '20%', height: '100%' }}
                src={`${process.env.PUBLIC_URL}/assets/weekend.png`}
                alt="Banner Publicidade"
              />
            
          </div>

        </Container>
    </div>
  )
}
