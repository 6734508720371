import React from 'react'
import { Container, Nav, Row } from 'react-bootstrap'
import KeywordCard from './KeywordCard';

export default function KeywordGridBlog({tags, colors}) {
  return (        
    <Container className='my-4 d-flex align-items-center justify-content-center'>
        <div className='d-flex flex-row flex-wrap align-items-center justify-content-center'>
        {tags.map((tag, index) => (
          <KeywordCard key={index} name={tag} colors={colors} index={index} />
        ))}
        </div>



    </Container>
  )
}
