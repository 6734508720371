import React from 'react';
import { Pagination } from 'react-bootstrap';
import './pagination.css';

export default function Paginate({ totalItems, itemsPerPage, currentPage, onPageChange }) {
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    const handleClick = (pageNumber) => {
        onPageChange(pageNumber);
    };

    const renderPaginationItems = () => {
        let items = [];
        let startPage = Math.floor((currentPage - 1) / 3) * 3 + 1;
        let endPage = Math.min(startPage + 2, totalPages);

        // Add "First" and "Prev" buttons
        if (currentPage > 1) {
            items.push(<Pagination.First key="first" onClick={() => handleClick(1)} />);
            items.push(<Pagination.Prev key="prev" onClick={() => handleClick(currentPage - 1)} />);
        } else {
            items.push(<Pagination.First key="first" disabled />);
            items.push(<Pagination.Prev key="prev" disabled />);
        }

        // Add "..." before the first page group if needed
        if (startPage > 1) {
            items.push(<Pagination.Item key="1" onClick={() => handleClick(1)}>1</Pagination.Item>);
            items.push(<Pagination.Ellipsis key="ellipsis-prev" />);
        }

        // Add page numbers
        for (let page = startPage; page <= endPage; page++) {
            items.push(
                <Pagination.Item
                    key={page}
                    active={page === currentPage}
                    onClick={() => handleClick(page)}
                >
                    {page}
                </Pagination.Item>
            );
        }

        // Add "..." after the last page group if needed
        if (endPage < totalPages) {
            items.push(<Pagination.Ellipsis key="ellipsis-next" />);
            items.push(<Pagination.Item key={totalPages} onClick={() => handleClick(totalPages)}>
                {totalPages}
            </Pagination.Item>);
        }

        // Add "Next" and "Last" buttons
        if (currentPage < totalPages) {
            items.push(<Pagination.Next key="next" onClick={() => handleClick(currentPage + 1)} />);
            items.push(<Pagination.Last key="last" onClick={() => handleClick(totalPages)} />);
        } else {
            items.push(<Pagination.Next key="next" disabled />);
            items.push(<Pagination.Last key="last" disabled />);
        }

        return items;
    };

    return (
        <Pagination className='d-flex align-items-center justify-content-center custom-pagination'>
            {renderPaginationItems()}
        </Pagination>
    );
}
