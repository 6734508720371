import React, { useEffect, useRef } from 'react'
import ImageBlog from '../components/Blog/ImageBlog'
import Container from './../../node_modules/react-bootstrap/esm/Container';
import TitleBlog from '../components/Blog/TitleBlog';
import Row from './../../node_modules/react-bootstrap/Row';
import Col from './../../node_modules/react-bootstrap/Col';
import ContentBlog from '../components/Blog/ContentBlog';
import Navbar from '../components/Navbar/Navbar';
import Footer from '../components/Footer/Footer';
import AdBigBanner from '../components/Ads/AdBigBanner';
import AdGrid3 from '../components/Ads/AdGrid3';
import KeywordGridBlog from '../components/Blog/KeywordGridBlog';
import { useParams } from 'react-router';
import SeeMore from '../components/Blog/SeeMore';

export default function BlogNormal() {
  const { nome } = useParams();

  //fazer query do blog com o id 

  // const BlogData = {category, title, subtitle, content, writer, updateDate, tags }
  const colors = ["primary", "secondary", "success"]
  const BlogData = {
                      category:'Marketing', 
                      title:'O rei da montanha volta ao planeta das baleias do norte maior', 
                      subtitle:'Acompanhe a nova jornada do ZéZé e sua viagem ao redor do mundo' , 
                      content:"Empreendedorismo Digital: Guia para Começar<br /><br />O Que é Empreendedorismo Digital?<br />Empreendedorismo digital envolve criar negócios operando principalmente online, como lojas virtuais, consultorias, marketing digital e desenvolvimento de aplicativos, com o potencial de alcançar um público global a baixo custo.<br /><br />Por Que Investir?<br />Flexibilidade: Trabalhe de qualquer lugar.<br />Baixo Custo Inicial: Menos recursos financeiros necessários.<br />Alcance Global: A internet permite atingir clientes em qualquer lugar.<br /><br />Passos para Começar<br />Identifique Sua Paixão e Nicho: Combine habilidades e interesses.<br />Crie um Plano de Negócios: Defina objetivos e estratégias.<br />Desenvolva Sua Presença Online: Invista em um site e marketing digital.<br />Teste e Ajuste: Use ferramentas de análise para monitorar e ajustar.<br /><br />Exemplo de Sucesso: Nubank<br />Início: Fundado em 2013, oferecendo um cartão de crédito sem tarifas via aplicativo.<br />Estratégias: Foco no cliente, inovação tecnológica, marketing digital eficaz e modelo de negócios escalável.<br />Impacto: Expansão para a América Latina e listagem na Bolsa de Valores de Nova York em 2021.<br /><br />Lições do Nubank<br />Foco no cliente.<br />Uso inteligente da tecnologia.<br />Marketing autêntico.<br />Modelo de negócios inovador.<br /><br />O sucesso do Nubank mostra como o empreendedorismo digital pode transformar indústrias estabelecidas. Para mais insights e dicas, acompanhe nosso blog.",
                      writer:'Yara Macedo', 
                      updateDate:'05 de novembro de 2024', 
                      tags:['A','B','C','D','E','F','G','H', 'I','J','K','L','M','N','O'],
                      img:'MainAfter.png',
                      references:'http:/sdsd/sd/sd/'
                    }
  
  const endOfPageRef = useRef(null);

  const scrollToBottom = () => {
    endOfPageRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() =>{
    console.log(nome)
  })

  return (
    <div
    style={{
      maxHeight: "100vh",
      maxWidth: "100%",
      overflowX: "hidden",
    }}
    >

    <Navbar newsletterEvent={scrollToBottom} />

    <AdBigBanner />


    <Container 
      // style={{ backgroundColor: '#f0f0f0' }}
      className='p-4 w-75'
    >
      <Row>
        <Col md={9} sm={12}>
          <Row className="justify-content-center align-items-center">
            <Col>
              <TitleBlog 
                category={BlogData.category}
                title={BlogData.title}
                subtitle={BlogData.subtitle}
                writer={BlogData.writer}
                updateDate={BlogData.updateDate} />
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center">
            <Col>
              <ImageBlog img={BlogData.img} references={BlogData.references} />
            </Col>
          </Row>

          <Row className="justify-content-center align-items-center">
            <Col>
              <ContentBlog content={BlogData.content} />
            </Col>
        <KeywordGridBlog tags={BlogData.tags} colors={colors} />

          </Row>
        </Col>

        <Col md={1}>
        </Col>

        <Col md={2} className="d-none d-md-block">
          <img
            style={{ maxWidth: "100%", height: "100%" }}
            src={`${process.env.PUBLIC_URL}/assets/banner.png`} />
        </Col>


      </Row>
    </Container>

    <AdGrid3 />

    <SeeMore />
    <Footer endOfPage={endOfPageRef} />

  </div>
  )
}
