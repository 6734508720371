import React from 'react'
import { Navbar, Nav, Container } from 'react-bootstrap';
import CategoryCard from './CategoryCard';

export default function CategoryNavbar() {

  const category = ['Empresas e Negócios', 'Estratégias', 'Inovação', 'Marketing', 'Tecnologia']

  return (
    <div className='categoryBar' style={{ marginTop: '4.3em'}}>
      <Navbar expand="lg" className='bg-light p-1'>
        <Container>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: '#fff', backgroundColor: 'white'}}/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className='me-auto mx-auto nav-center'>
              {category.map(cat => 
                <CategoryCard key={cat} name={cat} />
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      </div>
  )
}
