import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import MediumBlogCard from '../BlogCard/MediumBlogCard'

export default function BlogGrid3() {
  return (
    <Container className='my-3'>
      <Row 
        className='d-flex align-items-center justify-content-center'
        style={{ width: '100%' }}>
        <Col lg={3} md={12} className='d-flex mb-4'>
          <MediumBlogCard img={'a.jpg'} category={'Categoria'} title={'BANANAO 2.0'} subtitle={'O MAMACO TA BICHO'}/>
        </Col>

        <Col lg={3} md={12} className='d-flex mb-4'>
          <MediumBlogCard img={'a.jpg'} category={'Categoria'} title={'BANANAO 2.0'} subtitle={'O MAMACO TA BICHO'}/>
        </Col>

        <Col lg={3} md={12} className='d-flex mb-4'>
          <MediumBlogCard img={'a.jpg'} category={'Categoria'} title={'BANANAO 2.0'} subtitle={'O MAMACO TA BICHO'}/>
        </Col>
      </Row>
    </Container>
  )
}
