import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useLocation } from 'react-router-dom';
import SearchBar from '../SearchBar/SearchBar';

import './navbar.css'
import CategoryNavbar from './CategoryNavbar';

export default function NavbarComponent({newsletterEvent}) {
  let location = useLocation();

  return (
    <>
      <Navbar expand="lg" className='bg-dark' fixed='top'>
        <Container>
          <Navbar.Brand href="/">
            <img
              src={process.env.PUBLIC_URL + '/images/logo.png'}
              alt="logo"
              className="d-inline-block align-text-center"
              width="40"
              height="40"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" style={{ borderColor: '#fff', backgroundColor: 'white'}}/>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/destaques" className="text-white">Destaques</Nav.Link>
              {/* Uma página que mostra os mais recentes de cada categoria */}
              <Nav.Link href="/sobre-nos" className="text-white">Sobre Nós</Nav.Link>
              <Nav.Link href="/contato" className="text-white">Contatos</Nav.Link>
            </Nav>
            <Nav>
              <Nav.Link 
                // href="/login" 
                className="btn me-2" 
                style={{color: '#FF6347', transition: '.3s', fontWeight: "700"}}   
                onMouseOver={(e) => {
                  e.currentTarget.style.backgroundColor = '#FF6347'; 
                  e.currentTarget.style.color = '#332d2d'; 
                  // e.currentTarget.style.fontWeight = 'bold'
                }}
                onMouseOut={(e) => {
                  e.currentTarget.style.backgroundColor = 'transparent'; 
                  e.currentTarget.style.color = '#FF6347'; 
                  // e.currentTarget.style.fontWeight = 'normal'
                }}
                onClick={newsletterEvent}
              >
                Inscreva-se na Newsletter
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

	<CategoryNavbar />
    </>
  );
}
