import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import HeadlineBlogCard from '../BlogCard/HeadlineBlogCard'

export default function BlogHeadlineGrid3() {
  return (
    <Container className='my-3 d-flex align-items-center justify-content-center'>

      <Row style={{ width: '100%' }}>
        <Col lg={4} md={12} className='d-flex my-3'>
          <HeadlineBlogCard
            headline={'MACACO QUER BANANA'}
            img={'a.jpg'}
            category={'PAPAI NOEL'}
            title={'ACAMPAMENTO DE VERÃO DO SENHOR ELFRIDES'}
            subtitle={'FERIAS DE VERÂO NO NORTE DA MACEDÔNIA'}
            />
        </Col>

        <Col lg={4} md={12} className='d-flex my-3'>
          <HeadlineBlogCard
            headline={'MACACO QUER BANANA'}
            img={'a.jpg'}
            category={'PAPAI NOEL'}
            title={'ACAMPAMENTO DE VERÃO DO SENHOR ELFRIDES'}
            subtitle={'FERIAS DE VERÂO NO NORTE DA MACEDÔNIA'}
            />
        </Col>

        <Col lg={4} md={12} className='d-flex my-3'>
          <HeadlineBlogCard
            headline={'MACACO QUER BANANA'}
            img={'a.jpg'}
            category={'PAPAI NOEL'}
            title={'ACAMPAMENTO DE VERÃO DO SENHOR ELFRIDES'}
            subtitle={'FERIAS DE VERÂO NO NORTE DA MACEDÔNIA'}
            />
        </Col>
      </Row>

    </Container>
  )
}
