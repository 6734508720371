import React from 'react'
import { Nav } from 'react-bootstrap';
import FormatText from '../../utils/FormatText';

export default function CategoryCard({name}) {
  const formattedName = FormatText(name)

  return (
    <Nav.Link 
      href={`/categoria/${formattedName}`}
      className="btn me-3 py-0 px-2" 
      style={{color: '#212529', transition: '.3s'}}   
      onMouseOver={(e) => {
        e.currentTarget.style.backgroundColor = '#212529'; 
        e.currentTarget.style.color = '#FF6347'; 
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.backgroundColor = 'transparent'; 
        e.currentTarget.style.color = '#212529'; 
      }}
    >
      {name}
    </Nav.Link>
  )
}
