import Router from "./Router";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

axios.defaults.baseURL =
	window.location.protocol + '//' + window.location.hostname + ':' + 4005;

  
function App() {
  return (
    <div className="App">
      <Router />

    </div>
  );
}

export default App;
