import React, { useState } from 'react';
import { Card, Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FormatText from '../../utils/FormatText';
import KeywordGridBlog from '../Blog/KeywordGridBlog';

export default function LargeBlogCard({ img, category, title, subtitle, writer, update, tags }) { //tag é uma lista de tags que o blog possui --> fazer um map  
  const FormattedCategory = category ? FormatText(category) : '';
  const colors = ["primary", "secondary", "success"]

  const linkBlog = `/blog/${FormatText(title)}`
  
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredCategory, setIsHoveredCategory] = useState(false);

  /* HOVER */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterCategory = () => {
    setIsHoveredCategory(true);
  };
  const handleMouseLeaveCategory = () => {
    setIsHoveredCategory(false);
  };

  /* ONCLICK */
  

  return (
    <Card style={{ width: '100%' }} className='bg-light'>

      <Link to={linkBlog}>
        <Card.Img 
          className='px-3 pt-3'
          variant="top"
          src={`${process.env.PUBLIC_URL}/assets/${img}`}
          style={{ width: '100%', height: 'auto', margin: '0px auto', padding: '0px', cursor: 'pointer'}}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </Link>

      <Card.Body className='text-center p-2'>
        <Card.Text
            onMouseEnter={handleMouseEnterCategory}
            onMouseLeave={handleMouseLeaveCategory}
            className='mb-1'
            >
            <Link
            style={{fontWeight: 'bold', fontSize: '.9em', margin: '0 auto', cursor: 'pointer', color: isHoveredCategory ? '#FA8072' : '#FF6347',}}
            to={`/categoria/${FormattedCategory}`}>
              {category}
            </Link>            
        </Card.Text>

        <Card.Title
          className='mb-3'
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          >
            <Link
              style={{fontWeight: 'bold', fontSize: '2.2em', color: isHovered ? '#777' : 'black', cursor: 'pointer', textDecoration:"none"}}
              to={linkBlog}>
                {title}
            </Link>          
        </Card.Title>
        
        <Card.Subtitle 
          className="mb-2 text-muted"
          style={{}}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          >
            <Link
              style={{fontSize: '1.2em', color: 'black', cursor: 'pointer', textDecoration:"none"}}
              to={linkBlog}>
                {subtitle}
            </Link>      
        </Card.Subtitle>

        <Card.Text className='mt-3 mb-0'>
          <small className="text-muted">{writer}</small>
        </Card.Text>

        <Card.Text className=''>
          <small className="text-muted">{update}</small>
        </Card.Text>

        <div className='pb-2'>
        <KeywordGridBlog tags={tags} colors={colors} />
        </div>
      </Card.Body>
    </Card>
  );
}
