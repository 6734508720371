import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import BlogNormal from './pages/BlogNormal'
import CategoryPage from './pages/CategoryPage'
import Destaques from './pages/Destaques'

export default function Router() {
  return (
    <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/destaques" element={<Destaques/>} />
            <Route path="/blog/:nome" element={<BlogNormal/>} />
            <Route path="/categoria/:cat" element={<CategoryPage/>} />
        </Routes>
    </BrowserRouter>
  )
}
