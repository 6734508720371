import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Button } from 'react-bootstrap';

import { FaFacebook, FaWhatsapp } from 'react-icons/fa';
import { FaSquareXTwitter } from "react-icons/fa6";


const ShareButtons = () => {
  const url = window.location.href;
  const title = 'Título da Página';

  const shareOnFacebook = () => {
    window.location.href = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
  };

  const shareOnXTwitter = () => {
    window.location.href = `https://twitter.com/intent/tweet?text=${encodeURIComponent(title)}&url=${encodeURIComponent(url)}`;
  };

  const shareOnWhatsapp = () => {
    window.location.href = `https://api.whatsapp.com/send?text=${encodeURIComponent(title + ' ' + url)}`;
  };

  return (
    <Container className="d-flex justify-content-md-end mt-2 mt-md-0 p-0">
      <p className="text-center" onClick={shareOnFacebook}>
        <FaFacebook 
          size={25} 
          className="me-2"
          style={{cursor: 'pointer', color: '#1877F2'}} 
        />
      </p>

      <p className="text-center" onClick={shareOnXTwitter}>
        <FaSquareXTwitter 
          size={25} 
          className="mx-2"
          style={{cursor: 'pointer'}} 
        />
      </p>

      <p className="text-center" onClick={shareOnWhatsapp}>
        <FaWhatsapp 
          size={25} 
          className="ms-2"
          style={{cursor: 'pointer', color: '#25D366'}} 
        />
      </p>
    </Container>

  );
};

export default ShareButtons;
