import React from 'react'
import { Badge, Nav } from 'react-bootstrap';
import FormatText from '../../utils/FormatText';
import { Link } from 'react-router-dom';

export default function KeywordCard({name,colors,index}) {
  const formattedTag = FormatText(name)
  return (
    // <Nav.Link 
    //     href={`/keywords/${formattedTag}`}
    //     className="btn me-md-3 mb-2 mx-2 py-0 px-2" 
    //     style={{color: '#FF6347', backgroundColor: "#212529", transition: '.3s'}}   
    //     onMouseOver={(e) => {
    //         e.currentTarget.style.backgroundColor = '#414A4C'; 
    //     }}
    //     onMouseOut={(e) => {
    //         e.currentTarget.style.backgroundColor = '#212529'; 
    //     }}
    //     >
    //     {name}
    // </Nav.Link>


    colors && 
    <Badge key={index} bg={colors[index % colors.length]} 
      className="mx-2 my-1">
      <Link 
        className="px-1 py-0"
        to={`/keywords/${formattedTag}`}
        style={{textDecoration: 'none', color: 'white', fontSize: '1.2em'}}
        >
        {name}
      </Link>
    </Badge>
  )
}
