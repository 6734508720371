import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import './blog.css';
import ShareButtons from './ShareButtons';
import FormatText from '../../utils/FormatText';
import { Link } from 'react-router-dom';

export default function TitleBlog({ category, title, subtitle, writer, updateDate }) {
  const FormattedCategory = category ? FormatText(category) : '';

  const [isHoveredCategory, setIsHoveredCategory] = useState(false);

  const handleMouseEnterCategory = () => {
    setIsHoveredCategory(true);
  };
  const handleMouseLeaveCategory = () => {
    setIsHoveredCategory(false);
  };

  return (
    <Container className="" style={{ overflowWrap: "break-word" }}>

      <h6
        className='mx-auto m-3 my-2'
        style={{fontWeight: 'bold', fontSize: '1em', cursor: 'pointer', color: isHoveredCategory ? '#FA8072' : '#FF6347', width: "100%"}}
        onMouseEnter={handleMouseEnterCategory}
        onMouseLeave={handleMouseLeaveCategory}
        >
          <Link
          style={{fontWeight: 'bold', fontSize: '.9em', margin: '0 auto', cursor: 'pointer', color: isHoveredCategory ? '#FA8072' : '#FF6347',}}
          to={`/categoria/${FormattedCategory}`}>
            {category}
          </Link>      
      </h6>

      <h1
        className="mx-auto m-2"
        style={{
          letterSpacing: "-.0104166667em",
          fontWeight: "700",
          lineHeight: "1",
          marginBottom: "0",
          fontSize: "2em",
          width: "100%",
        }}
      >
        {title}
      </h1>

      <h3
        className="mx-auto m-2"
        style={{
          letterSpacing: "-.0104166667em",
          fontSize: "1.5em",
          lineHeight: "1",
          marginBottom: "0",
          color: "black",
          maxWidth: "100%",
        }}
      >
        {subtitle}
      </h3>
      
      <div className='mx-auto d-flex flex-md-row flex-column justify-content-lg-between mt-3 mb-0' style={{width: '100%'}}>
        <div className="d-flex flex-column flex-grow-1 align-items-start">
          <h6
            className="text-muted mb-0"
            style={{
              fontSize: "1em",
              color: "black",
              maxWidth: "100%",
            }}
          >
            Por {writer}
          </h6>

          <h6
            className="text-muted mb-0 ml-sm-2"
            style={{
              fontSize: ".8em",
              color: "black",
              maxWidth: "100%",
            }}
          >
            {updateDate}
          </h6>
        </div>

        <div className="mt-2 mt-sm-0">
          <ShareButtons />
        </div>
      </div>


    </Container>
  );
}
