import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

export default function ContentBlog({content}) {
  return (
    <Container>
      <Row>
        <Col className="d-flex justify-content-center">
          <p 
            className="mx-auto"
            style={{maxWidth: '100%', fontSize: "1em"}}
          >
            {content}
          </p>
        </Col>
      </Row>
    </Container>
  );
}
