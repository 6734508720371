import React, { useState } from 'react';
import { Card, Badge, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FormatText from '../../utils/FormatText';

export default function SmallBlogCard({title, subtitle}) {

  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredCategory, setIsHoveredCategory] = useState(false);

  const linkBlog = `/blog/${FormatText(title)}`

  /* HOVER */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  /* ONCLICK */

  return (
    <Card className='mb-4 bg-light' style={{ width: '100%'}}>
      <Row>
        <Card.Body className='text-center p-2'>
          <Card.Title
            className='mb-3'
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
              <Link
                style={{fontWeight: 'bold', fontSize: '1.5em', color: isHovered ? '#777' : 'black', cursor: 'pointer', textDecoration:"none"}}
                to={linkBlog}>
                  {title}
              </Link>          
          </Card.Title>

          <Card.Subtitle 
            className="mb-2 text-muted"
            style={{}}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            >
              <Link
                style={{fontSize: '1em', color: 'black', cursor: 'pointer', textDecoration:"none"}}
                to={linkBlog}>
                  {subtitle}
              </Link>      
          </Card.Subtitle>

          </Card.Body>
      </Row>
    </Card>
  );
}
