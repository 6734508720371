import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import FormatText from '../../utils/FormatText';

export default function SlideshowBlogCard({ blogsInfo }) {

    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredCategory, setIsHoveredCategory] = useState(false);
      /* HOVER */
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const handleMouseEnterCategory = () => {
    setIsHoveredCategory(true);
  };
  const handleMouseLeaveCategory = () => {
    setIsHoveredCategory(false);
  };


  return (
    blogsInfo && <Carousel interval={5000}  >
      {blogsInfo.map((blog, index) => (
        <Carousel.Item key={index}>
          <img
            className='d-block w-100 p-3'
            style={{maxHeight: '65vh', height: 'auto', filter: 'brightness(55%)'}}
            src={`${process.env.PUBLIC_URL}/assets/${blog.img}`} 
            alt={`Slide ${index + 1}`}
          />
        <Carousel.Caption
            className='bg-light text-dark'
            style={{boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)', opacity: '0.75', borderRadius: '1em'
                // backgroundColor: 'rgba(0, 0, 0, 0.6)',
            }}>

            <Link
                onMouseEnter={handleMouseEnterCategory}
                onMouseLeave={handleMouseLeaveCategory}
                style={{fontWeight: 'bold', fontSize: '.9em', margin: '0 auto', cursor: 'pointer', color: isHoveredCategory ? '#FA8072' : '#FF6347',}}
                to={`/categoria/${FormatText(blog.categoria)}`}>
                <p>{blog.categoria}</p>
            </Link>

            <Link 
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                style={{fontWeight: 'bold', fontSize: '1.5em', color: isHovered ? '#777' : 'black', cursor: 'pointer', textDecoration:"none"}}
                to={`/blog/${FormatText(blog.titulo)}`}>
                <h3>{blog.titulo}</h3>
            </Link>

        </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  );
}
