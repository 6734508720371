import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LargeBlogCard from '../BlogCard/LargeBlogCard';
import MediumBlogCard from '../BlogCard/MediumBlogCard';
import SmallBlogCard from '../BlogCard/SmallBlogCard';
import SearchBar from '../SearchBar/SearchBar';

export default function Last7Blogs() {
  return (
    <Container className='d-flex align-items-center justify-content-center my-3'>

      <Row style={{ width: '100%' }}>
        <Col lg={6} md={12} className='d-flex mb-4'>
          <Row style={{ width: '100%' }}>
            <LargeBlogCard 
              category={'empresas'} 
              img={'MainAfter.png'} 
              title={'Catapimbas'} 
              subtitle={'Invest in prediction, adaptability, and resilienceAAAAAAAAAAAAAAAaAAAAAAA'}
              writer={'Yara Macedo'}
              update={'Updated on June 4, 2024'}
              tags={['Tag1', 'Tag2', 'Tag3', 'Tag4']}
              />
          </Row>
        </Col>

        <Col lg={3} md={12} className='d-flex'>
          <Row style={{ width: '100%' }}>
            <MediumBlogCard img={'a.jpg'} category='Baiano' title={'BANANAO 2.0'} subtitle={'O MAMACO TA BICHO'}/>
            <MediumBlogCard img={'a.jpg'} category={'Categoria'} title={'BANANAO 2.0'} subtitle={'O MAMACO TA BICHO'}/>
          </Row>
        </Col>

        <Col lg={3} md={12} className='d-flex'>
          <Row style={{ width: '100%' }}>
            {/* VER MAIS */}
            <SmallBlogCard title={'BICHO DO MATO'} subtitle={'BLINDAO BLI BLI BLI BLINDAO'} />
            <SmallBlogCard title={'BICHO DO MATO'} subtitle={'BLINDAO BLI BLI BLI BLINDAO'} />
            <SmallBlogCard title={'BICHO DO MATO'} subtitle={'BLINDAO BLI BLI BLI BLINDAO'} />
            <SmallBlogCard title={'BICHO DO MATO'} subtitle={'BLINDAO BLI BLI BLI BLINDAO'} />
          </Row>
        </Col>
      </Row>

    </Container>
  );
}
