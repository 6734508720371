
export default function FormatText(text) {
    // Transforma em minúsculas
    let formattedText = text.toLowerCase();
    
    // Remove acentos
    formattedText = formattedText.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    
    // Substitui espaços por hífens
    formattedText = formattedText.replace(/\s+/g, '-');
  
    return formattedText;
}
