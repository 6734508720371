import React from 'react'
import { Container } from 'react-bootstrap'
import './banner.css'
export default function AdBigBanner() {
  return (
    <div>
        <Container className="d-flex justify-content-center my-4 banner p-1">
          <div 
            style={{ width: '970px', height: '250px' }} 
            className='d-flex align-items-center justify-content-center'
          >
            <img
              className='p-2'
              style={{ width: '100%', height: '100%' }}
              src={`${process.env.PUBLIC_URL}/assets/banner-publicidade.png`}
              alt="Banner Publicidade"
            />
          </div>
        </Container>
    </div>
  )
}
