import React from 'react'
import BlogGrid3 from '../BlogCatalog/BlogGrid3'
import { Container } from 'react-bootstrap'

export default function SeeMore() {
  return (
    <Container className='d-flex flex-column align-items-center justify-content-center'>
        <h2> Veja Também </h2>
        <BlogGrid3 />
    </Container>
  )
}
